import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ProjektCarousel from './ProjektCarousel';
import Header from './Header';
import Footer from './Footer';
import CookieBanner from './CookieBanner'; // Cookie-Banner importieren

function AppContent() {
  const [showCarousel, setShowCarousel] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true); // Zustand für den ersten Besuch
  const location = useLocation(); // Verwende den useLocation-Hook

  const handleVideoEnd = () => {
    setShowCarousel(true); // Wechselt zu Carousel, wenn das Video endet
  };

  const isOnLegalPage = location.pathname === '/Impressum' || location.pathname === '/Datenschutz';

  // Überprüfen, ob der Nutzer die Seite schon besucht hat
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisited');
    
    if (hasVisitedBefore) {
      setIsFirstVisit(false); // Wenn der Nutzer die Seite schon besucht hat, wird der Zustand geändert
    } else {
      localStorage.setItem('hasVisited', 'true'); // Wenn es der erste Besuch ist, speichern wir diese Information
    }
  }, []);

  return (
    <div className="min-h-screen bg-black flex flex-col">
      {/* Header wird immer angezeigt, es sei denn der Nutzer ist auf einer der rechtlichen Seiten */}
      {!isOnLegalPage && <Header />}

      <div className="flex-grow flex items-center justify-center">
        {/* Das Video wird nur angezeigt, wenn der Nutzer es noch nicht gesehen hat */}
        {!showCarousel && isFirstVisit ? (
          <div className="w-full h-auto sm:h-auto lg:h-auto">
            <video
              src="/introwebsite.mp4" // Hier dein Video im public-Ordner referenzieren
              className="w-full h-auto"
              autoPlay
              muted
              playsInline // Inline-Wiedergabe auf iPhone erlauben
              preload="auto" // Video wird vorgeladen
              onEnded={handleVideoEnd}
            />
          </div>
        ) : (
          !isOnLegalPage && <ProjektCarousel /> // Zeigt das Karussell an, wenn das Video endet und wir nicht auf Impressum/Datenschutz sind
        )}
      </div>

      <Footer />
      <CookieBanner />

      {/* Routes definieren, die nur angezeigt werden, wenn die entsprechenden Seiten aufgerufen werden */}
      <Routes>
        <Route path="https://impressum.gager.work   "  />
        <Route path="https://datenschutz.gager.work" />
        <Route path='https://kontakt.gager.work'  /> 
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
