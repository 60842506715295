import React from 'react';


const projektsCard = ({ projekts }) => {

  return (
    <div className="flex flex-col items-center bg-white p-10 rounded-lg shadow-lg transition-transform duration-500 transform">
      {/* Bild nur anzeigen, wenn es vorhanden ist */}
      {projekts.image && (
        <img
          className="w-40 h-40 rounded-full mb-6"
          src={projekts.image}
          alt={`${projekts.name}'s profile`}
        />
      )}
      <h2 className="text-2xl font-semibold text-gray-800">{projekts.name}</h2>
      <p className="text-gray-700 mb-4">{projekts.job}</p>

      {/* Website nur anzeigen, wenn vorhanden */}
      {projekts.website && (
        <div className="mt-6">
          <a href={projekts.website} className="bg-gray-700 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-gray-700 transition-colors">
            <button>
              Website
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default projektsCard;
